@import 'colors';
@import 'fonts';
@import 'media-queries';

@import 'components/Error/styles';
@import 'components/Header/styles';
@import 'components/Results/styles';
@import 'components/Search/styles';
@import 'components/Loader/styles';
@import 'components/Footer/styles';
@import 'components/Recent/styles';

body {
  background: $tertiary-color;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 0 15px;
}
