@import 'styles/media-queries';

.results {
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 0 5px 1px #aaa;
  box-sizing: border-box;
  display: grid;
  font-family: sans-serif;
  grid-gap: 15px;
  list-style: none;
  margin: 0 auto 30px;
  max-width: 800px;
  padding: 25px;
  @include desktop {
    grid-template-columns: 1fr 1fr;
  }

  &--recent {
    box-shadow: none;
    margin: 0;
  }

  &__item {
    display: flex;
    font-size: 18px;
    min-height: 30px;
    justify-content: space-between;
    margin: 0;


    @include desktop {
      justify-content: initial;

      &:nth-child(even):last-child() {
        grid-column: 1/ -1;
      }
    }
  }

  &__header {
    display: grid;
    grid-gap: 5px;
    margin: 0 0 15px;
    @include desktop {
      grid-column: 1 / -1;
      grid-template-columns: auto 1fr;
      justify-items: end;
      margin: 0;
    }
  }

  &__image {
    display: block;
    width: 50px;
    @include desktop {
      margin: 5px 0 0;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 5px;
  }

  &__subtitle {
    font-size: 18px;
    margin: 0 0 5px;
  }

  &__label {
    font-weight: 600;
    margin: 0 10px 0 0;
  }

  &__address {
    display: block;
    font-style: normal;
    text-align: right;

    @include desktop {
      text-align: initial;
    }
  }

}
