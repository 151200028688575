.header {
  align-items: center;
  background: $secondary-color;
  box-sizing: border-box;
  display: grid;
  justify-items: center;
  margin: 0 0 30px;
  padding: 15px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;

  @include desktop-xl {
    grid-template-columns: 1fr 2fr 1fr;
    height: 85px;
    padding: 0 15px;
  }
}

.logo {
  color: #fff;
  font-family: digital, sans-serif;
  font-size: 32px;
  font-weight: 600;
  margin: 0 0 15px;
  text-align: center;

  @include tablet {
    font-size: 40px;
    text-align: initial;
  }

  @include desktop-xl {
    justify-self: start;
    margin: 0;
  }
}
