@mixin tablet {
  @media(min-width: 360px) {
    @content;
  }
}
@mixin desktop {
  @media(min-width: 760px) {
   @content; 
  }
}

@mixin desktop-xl {
  @media(min-width: 1200px) {
    @content;
  }
}
