.search {
  @include tablet {
    display: flex;
    max-width: 800px;
    width: 100%;
  }

  &__input {
    border: 1px solid #BBB;
    border-radius: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    height: 50px;
    outline: none;
    padding: 0 15px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    &::placeholder {
      text-transform: none;
    }
    @include tablet {
      border-radius: 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: 0;
      font-size: 24px;
      text-align: initial;
    }
  }

  &__button {
    background: $primary-color;
    border: 0;
    border-radius: 0;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-sizing: border-box;
    color: #FFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    margin: 0;
    padding: 0 40px;
    text-transform: uppercase;
    transition: background 0.2s;
    width: 100%;
    &:hover {
      background: darken(#F77E31, 10%);
    }

    @include tablet{
      border: 0;
      border-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      width: initial;
    }
  }

  &__error {
    color: #F00;
    font-family: sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin: 15px 0 0;
    text-align: center;
  }

}
